@font-face {
  font-family: 'FuturaPT';
  src: local('FuturaPT'),
    url(/fonts/FuturaPT/FuturaPTBook.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'FuturaPT', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  width: 100%;
  height: calc(100vh - 120px);
  overflow-x: hidden;
  display: flex;
}

.onyx-video-player {
  position: relative;
  width: 100%;
  /* height: calc(100vh - 230px); */
  height: 76%;
}

.onyx-sponsor-logo {
  width: 220px;
  padding: 20px;
  border-right-width: 3px;
  border-right-style: solid;
  display: flex;
  align-items: center;
}

.main-header {
  min-height: 50px;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: white;
  border-bottom: 1px solid gray;
}

.main-logo {
  width: 35px;
  height: 35px;
}

.header-logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-left-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-menu-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  height: 50px;
}

.header-right-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.side-menu-container {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-right: 50px;
  height: 50px;
}

.search-icon {
  width: 13px;
  height: 13px;
}

.menu-item {
  margin-right: 30px;
  height: 100%;
}

.menu-link {
  position: relative;
  cursor: pointer;
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #e4ec1c;
  bottom: 0;
  opacity: 0;
  transition: all 0.5s ease-out;
}

.menu-link:hover::after {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.header-logo-wrapper {
  margin-right: 30px;
}

.page-main {
  background-color: #0c161c;
  padding: 25px 0;
}

.video-container-title {
  margin: 0;
  color: white;
  margin-bottom: 20px;
}

.competitions-item-image {
  width: 400px;
  height: auto;
  margin-bottom: 8px;
}

.competitions-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.competitons-item-link {
  display: block;
  transition: all 1s ease-out;
}

.competitons-item-link-wrapper {
  width: fit-content;
  padding: 40px 0;
  padding-top: 20px;
}

.competitons-item-link:hover {
  transform: scale(1.1);
  transition: all 1s ease-out;
}

.features-item-link {
  display: block;
  cursor: pointer;
  transition: all 1s ease-out;
}

.features-item-link:hover {
  transform: scale(1.1);
  transition: all 1s ease-out;
}

.competition-item-title {
  font-size: 15px;
  color: white;
  margin: 0;
  padding: 0;
}

.competitions-subtitle {
  font-size: 13px;
  color: white;
}

.competitons-item-link {
  cursor: pointer;
}

.competitions-image-wrapper {
  width: 180px;
  height: 180px;
  margin-bottom: 8px;
}

.features-item-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.arrow-down {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.menu-link--arrow {
  position: relative;
  padding-right: 16px;
}

.menu-link--arrow:before {
  content: '';
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 0;
  top: 19px;
}

.side-menu-list {
  opacity: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  width: 71px;
  bottom: -59px;
  padding-top: 10px;
  transition: all 0.5s ease-out;
}

.menu-link--right:hover .side-menu-list {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.menu-link--sports:hover .side-menu-list {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.video-container {
  margin-bottom: 40px;
  padding: 0 20px;
}

.features-container {
  padding: 0 20px;
}

.competitions-container {
  padding: 0 20px;
}

.competitions-container .video-container-title {
  margin-bottom: 0;
}

.features-item-link-wrapper {
  width: fit-content;
  padding: 40px 0;
  padding-top: 0px;
}

.competitions-container .swiper-button-next,
.swiper-button-prev {
  color: #e4ec1c;
}

.features-container .swiper-button-next,
.swiper-button-prev {
  color: #e4ec1c;
}

.welcome-screen {
  width: 100%;
  height: auto;
}

.logo-wrapper {
  /* position: absolute; */
  /* right: 20px;
  top: 30px; */
  z-index: 100;
}

.logo-wrapper img {
  object-fit: contain;
  height: 100%;
}

.arrow-wrapper {
  width: 19px;
  height: 13px;
  position: absolute;
  top: 16px;
  right: 30px;
}

.switch-button {
  width: 100px;
  height: 20px;
  border-radius: 8;
  background-color: #d94b6d;
  display: block;
  border-radius: 10px;
  margin-bottom: 5px;
}

.switch-button--green {
  background-color: #00f981;
}

.page-main-video-intro {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App_Left-side {
  /* height: calc(100vh - 230px); */
  background: #373737;
  height: 76%;
  width: 25%;
}
.App_Right-side {
  /* height: calc(100vh - 230px); */
  background: #373737;
  height: 76%;
  width: 30%;
}
.App_Right-side img {
  max-height: 100%;
}
.App_Left-side_info-button {
  background: linear-gradient(323.03deg, #065C96 -34.33%, rgba(9, 91, 150, 0.25) 86.13%);
  box-shadow: 0px 2.28346px 9.70469px rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8.56296px);
  width: 56px;
  height: 56px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
}
.App_Left-side_stat-button {
  background: linear-gradient(323.57deg, #DB1A3C -83.87%, rgba(221, 27, 52, 0.21) 96.14%);
  box-shadow: 0px 2.28346px 9.70469px rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8.56296px);
  width: 56px;
  height: 56px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
}
.App_info-stat_buttons {
  position: absolute;
    right: 3%;
    bottom: 11%;
    display: flex;
}
.back-to-players_button {
  width: 60px;
  height: 60px;
  background: linear-gradient(0deg, #1A1A1A, #1A1A1A), linear-gradient(159.54deg, rgba(240, 240, 240, 0.5) -110.64%, rgba(240, 240, 240, 0) 115.66%);
  opacity: 0.85;
  backdrop-filter: blur(26.512px);
  position: absolute;
  top: 5%;
  right: 5%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.back-to-players_button-img {
  width: 24px;
}